<template>
  <div class="yusu-planter">
    <section class="yusu-planter-letf">
      <img :src="imgTags" alt="" />
      <barrage class="yu-barrage" :itemList="barrageData"></barrage>
    </section>
    <div class="yusu-planter-before-mid"><img :src="imgTitle" alt="" /></div>
    <section class="yusu-planter-mid">
      <div
        class="yusu-planter-mid-item"
        v-for="(item, index) in cardList"
        :key="index"
        @click="onGoDetail(item.link)"
      >
        <img :src="item.img" alt="" />
      </div>
    </section>
    <div class="yusu-planter-before-right"><img :src="imgTitle2" alt="" /></div>
    <section class="yusu-planter-right">
      <div
        class="yusu-planter-right-item"
        v-for="(item, index) in bottomImgList"
        :key="index"
        @click="onGoDetail(item.link)"
      >
        <img :src="item.img" alt="" />
      </div>
    </section>
  </div>
</template>

<script>
import imgTags from '../../assets/images/walking-grass-planter/tags.png'
import imgTitle from '../../assets/images/walking-grass-planter/title.png'
import imgTitle2 from '../../assets/images/walking-grass-planter/title-2.png'
import card01 from '../../assets/images/walking-grass-planter/card-01.png'
import card02 from '../../assets/images/walking-grass-planter/card-02.png'
import card03 from '../../assets/images/walking-grass-planter/card-03.png'
import card04 from '../../assets/images/walking-grass-planter/card-04.png'
import card05 from '../../assets/images/walking-grass-planter/card-05.png'
import card06 from '../../assets/images/walking-grass-planter/card-06.png'
import card07 from '../../assets/images/walking-grass-planter/card-07.png'
import card08 from '../../assets/images/walking-grass-planter/card-08.png'
import card09 from '../../assets/images/walking-grass-planter/card-09.png'
import card10 from '../../assets/images/walking-grass-planter/card-10.png'
import card11 from '../../assets/images/walking-grass-planter/card-11.png'
import card12 from '../../assets/images/walking-grass-planter/card-12.png'
import card13 from '../../assets/images/walking-grass-planter/card-13.png'
import card14 from '../../assets/images/walking-grass-planter/card-14.png'
import card15 from '../../assets/images/walking-grass-planter/card-15.png'

import bottom01 from '../../assets/images/walking-grass-planter/bottom-01.png'
import bottom02 from '../../assets/images/walking-grass-planter/bottom-02.png'
import bottom03 from '../../assets/images/walking-grass-planter/bottom-03.png'
import bottom04 from '../../assets/images/walking-grass-planter/bottom-04.png'

import barrage from '../../components/barrage'
export default {
  name: 'walking',
  components: { barrage },
  data: () => {
    return {
      imgTags: imgTags,
      imgTitle: imgTitle,
      imgTitle2: imgTitle2,
      cardList: [
        {
          img: card01,
          link: 'http://xhslink.com/XgM0Xb',
        },
        {
          img: card02,
          link: 'http://xhslink.com/6NO0Xb',
        },
        {
          img: card03,
          link: 'http://xhslink.com/bST0Xb',
        },
        {
          img: card04,
          link: 'http://xhslink.com/laS0Xb',
        },
        {
          img: card05,
          link: 'http://xhslink.com/jeT0Xb',
        },
        {
          img: card06,
          link: 'http://xhslink.com/omd1Xb',
        },
        {
          img: card07,
          link: 'http://xhslink.com/pfk1Xb',
        },
        {
          img: card08,
          link: 'http://xhslink.com/1xk1Xb',
        },
        {
          img: card09,
          link: 'http://xhslink.com/f5i1Xb',
        },
        {
          img: card10,
          link: 'http://xhslink.com/L5n1Xb',
        },
        {
          img: card11,
          link: 'http://xhslink.com/2oo1Xb',
        },
        {
          img: card12,
          link: 'http://xhslink.com/CWl1Xb',
        },
        {
          img: card13,
          link: 'http://xhslink.com/Ocr1Xb',
        },
        {
          img: card14,
          link: 'http://xhslink.com/VyL9Od',
        },
        {
          img: card15,
          link: 'http://xhslink.com/Lkr1Xb',
        },
      ],
      bottomImgList: [
        {
          img: bottom01,
          link: 'https://mp.weixin.qq.com/s?__biz=Mzg2MDE2ODE4OA==&mid=2247486107&idx=1&sn=a60b9550b90ab6e91cdf980847a799f1&chksm=ce2bc008f95c491e9c3e37b284420a0c21509a9147e02631777dd2a8d8fcab198bbd709c9c39&token=1915849236&lang=zh_CN#rd',
        },
        {
          img: bottom02,
          link: 'https://mp.weixin.qq.com/s?__biz=Mzg2MDE2ODE4OA==&mid=2247485961&idx=1&sn=2e06350db16308ac59e9bb527c1149c5&chksm=ce2bc09af95c498c99a81937490eb054f5cf80edb3563f32d0b3ac8e3c0485c3ce4c9a3567a6&token=449927607&lang=zh_CN#rd',
        },
        {
          img: bottom03,
          link: 'https://mp.weixin.qq.com/s?__biz=Mzg2MDE2ODE4OA==&mid=2247486163&idx=1&sn=399fa54b9fc2ebe529f81cbeefac0a29&chksm=ce2bc040f95c49562a484d90d3c23a47700e7d55d52904418846bdb4b14fd8e0bc9057144a3a&token=1915849236&lang=zh_CN#rd',
        },
        {
          img: bottom04,
          link: 'https://mp.weixin.qq.com/s?__biz=Mzg2MDE2ODE4OA==&mid=2247485850&idx=1&sn=996439bfc179d99de62aff1811117aee&chksm=ce2bc309f95c4a1fb1ee79a9a7c5508f7e62a4b2da283a8d5da750ea499f6128a30579e8ed10&token=449927607&lang=zh_CN#rd',
        },
      ],
      barrageData: [
        {
          text: '#可以睁眼洗的洗面奶#',
          url: 'http://xhslink.com/lAoy7b',
          style: {
            fontSize: '0.15rem',
            color: ' rgb(155, 155, 155)',
          },
        },
        {
          text: '#一瓶喷雾修护三大敏感问题#',
          url: 'http://xhslink.com/E3my7b',
          style: {
            fontSize: '0.2rem',
            color: 'rgb(137, 163, 141)',
          },
        },
        {
          text: '#预防脱皮，赶跑红肿#',
          url: 'http://xhslink.com/Z3Cy7b',
          style: {
            fontSize: '0.18rem',
            color: 'rgb(155, 155, 155)',
          },
        },
        {
          text: '#拯救爆痘三件套#',
          url: 'http://xhslink.com/d0wy7b',
          style: {
            fontSize: '0.15rem',
            color: 'rgb(131, 181, 183)',
          },
        },
        {
          text: '#皮肤科专家力荐的痘痘好物#',
          url: 'http://xhslink.com/zvzy7b',
          style: {
            fontSize: '0.18rem',
            color: 'rgb(121, 145, 173)',
          },
        },
        {
          text: '#油痘肌护肤|拯救爆痘三件套#',
          url: 'http://xhslink.com/Z3Cy7b',
          style: {
            fontSize: '0.3rem',
            color: 'rgb(137, 163, 141)',
          },
        },
        {
          text: '#实用干货✨95%女生都会忽略的护肤细节#',
          url: 'http://xhslink.com/Rbqy7b',
          style: {
            fontSize: '0.12rem',
            color: 'rgb(155, 155, 155)',
          },
        },
        {
          text: '#养肤的无泪果冻洁面#',
          url: 'http://xhslink.com/6Npy7b',
          style: {
            fontSize: '0.19rem',
            color: 'rgb(137, 163, 141)',
          },
        },
        {
          text: '#痘痘少了很多#',
          url: 'http://xhslink.com/q0Ey7b',
          style: {
            fontSize: '0.11rem',
            color: 'rgb(137, 163, 141)',
          },
        },
        {
          text: '#油痘肌真爱精华 从根源减少痘痘#',
          url: 'http://xhslink.com/37yy7b',
          style: {
            fontSize: '0.15rem',
            color: ' rgb(121, 145, 173)',
          },
        },
      ],
    }
  },
  methods: {
    onGoDetail(url) {
      window.open(url, '_blank')
    },
  },
}
</script>
<style lang="scss" scoped>
.yusu-planter {
}
.yusu-planter-letf {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  .yu-barrage {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.yusu-planter-mid {
  width: 1450px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .yusu-planter-mid-item {
    cursor: pointer;
    width: 20%;
  }
}
.yusu-planter-before-mid {
  width: 1450px;
  margin: 30px auto;
  display: flex;
  justify-content: flex-end;
}
.yusu-planter-before-right {
  width: 1450px;
  margin: 30px auto;
  display: flex;
  justify-content: flex-end;
}
.yusu-planter-right {
  width: 1536px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .yusu-planter-right-item {
    margin: 4px;
    cursor: pointer;
    width: 47%;
  }
}
</style>
