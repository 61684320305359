<template>
  <div class="yusu-barrage-component">
    <div
      class="yusu-barrage-component-item"
      v-for="(item, index) in curDataList"
      :key="index + 'barrage'"
    >
      <div
        class="yusu-barrage-component-item-text"
        :style="item1.style"
        v-for="(item1, index1) in item"
        :key="index1 + 'barrageitem'"
        @click="goDetail(item1.url)"
      >
        {{ item1.text }}
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'barrage',
  props: {
    itemList: {
      default: () => {
        return [
          {
            text: '#可以睁眼洗的洗面奶#',
            url: 'http://xhslink.com/lAoy7b',
            style: {
              fontSize: '36px',
              color: ' rgb(155, 155, 155)',
            },
          },
          {
            text: '#一瓶喷雾修护三大敏感问题#',
            url: 'http://xhslink.com/E3my7b',
            style: {
              fontSize: '36px',
              color: 'rgb(137, 163, 141)',
            },
          },
          {
            text: '#止红痒 防脱皮 消红疹#',
            url: 'http://xhslink.com/Z3Cy7b',
            style: {
              fontSize: '36px',
              color: 'rgb(155, 155, 155)',
            },
          },
          {
            text: '#拯救爆痘三件套#',
            url: 'http://xhslink.com/d0wy7b',
            style: {
              fontSize: '36px',
              color: 'rgb(131, 181, 183)',
            },
          },
          {
            text: '#皮肤科专家力荐的痘痘好物#',
            url: 'http://xhslink.com/zvzy7b',
            style: {
              fontSize: '45px',
              color: 'rgb(121, 145, 173)',
            },
          },
          {
            text: '#油痘肌护肤|拯救爆痘三件套#',
            url: 'http://xhslink.com/Z3Cy7b',
            style: {
              fontSize: '52px',
              color: 'rgb(137, 163, 141)',
            },
          },
          {
            text: '#实用干货✨95%女生都会忽略的护肤细节#',
            url: 'http://xhslink.com/Rbqy7b',
            style: {
              fontSize: '32px',
              color: 'rgb(155, 155, 155)',
            },
          },
          {
            text: '#养肤的无泪果冻洁面#',
            url: 'http://xhslink.com/6Npy7b',
            style: {
              fontSize: '35px',
              color: 'rgb(137, 163, 141)',
            },
          },
          {
            text: '#痘痘少了很多#',
            url: 'http://xhslink.com/q0Ey7b',
            style: {
              fontSize: '25px',
              color: 'rgb(137, 163, 141)',
            },
          },
          {
            text: '#油痘肌真爱精华 从根源减少痘痘#',
            url: 'http://xhslink.com/37yy7b',
            style: {
              fontSize: '32px',
              color: ' rgb(121, 145, 173)',
            },
          },
        ]
      },
      type: Array,
    },
    nums: {
      type: Number,
      default: 3,
    },
  },
  data: () => {
    return {
      curDataList: [],
    }
  },
  created() {
    let count = Math.ceil(this.itemList.length / this.nums)
    let copyItemList = JSON.parse(JSON.stringify(this.itemList))
    for (let i = 0; i < count; i++) {
      this.curDataList.push(copyItemList.splice(0, this.nums))
    }
  },
  methods: {
    goDetail(url) {
      window.open(url, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
@keyframes animat_1 {
  0% {
    transform: translateX(1920px);
  }
  100% {
    transform: translateX(-900px);
  }
}

.yusu-barrage-component-item {
  .yusu-barrage-component-item-text {
    padding: 6px;
    cursor: pointer;

    &:nth-last-child(1) {
      transform: translateX(1920px);
      animation: animat_1 9s 0s infinite linear;
    }
    &:nth-last-child(2) {
      transform: translateX(1920px);
      animation: animat_1 9s 3s infinite linear;
    }
    &:nth-last-child(3) {
      transform: translateX(1920px);
      animation: animat_1 9s 6s infinite linear;
    }
    &:nth-last-child(4) {
      transform: translateX(1920px);
      animation: animat_1 9s 9s infinite linear;
    }
  }
}
</style>